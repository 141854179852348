import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "qa-ref-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuizCard = _resolveComponent("QuizCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.activeVerseString)
      ? (_openBlock(), _createBlock(_component_QuizCard, {
          key: 0,
          "ref-text": _ctx.activeVerseString,
          class: "qa-rc-card"
        }, null, 8, ["ref-text"]))
      : _createCommentVNode("", true)
  ]))
}